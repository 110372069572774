import { Col, Row, Select, message } from 'antd';
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, InputNumber } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { addUser, fetchOneUser, updateUser } from 'redux/features/user';
import { fetchAllWajib } from 'redux/features/wajib_pajak';
import Password from 'antd/lib/input/Password';


const kabupatens = [
  { "label": "SUMATERA SELATAN", "value": "SUMATERA SELATAN" },
  { "label": "BANYUASIN", "value": "BANYUASIN" },
  { "label": "EMPAT LAWANG", "value": "EMPAT LAWANG" },
  { "label": "LAHAT", "value": "LAHAT" },
  { "label": "MUARA ENIM", "value": "MUARA ENIM" },
  { "label": "MUSI BANYUASIN", "value": "MUSI BANYUASIN" },
  { "label": "MUSI RAWAS", "value": "MUSI RAWAS" },
  { "label": "MUSI RAWAS UTARA", "value": "MUSI RAWAS UTARA" },
  { "label": "OGAN ILIR", "value": "OGAN ILIR" },
  { "label": "OGAN KOMERING ILIR", "value": "OGAN KOMERING ILIR" },
  { "label": "OGAN KOMERING ULU", "value": "OGAN KOMERING ULU" },
  { "label": "OGAN KOMERING ULU SELATAN", "value": "OGAN KOMERING ULU SELATAN" },
  { "label": "OGAN KOMERING ULU TIMUR", "value": "OGAN KOMERING ULU TIMUR" },
  { "label": "PENUKAL ABAB LEMATANG ILIR", "value": "PENUKAL ABAB LEMATANG ILIR" },
  { "label": "LUBUKLINGGAU", "value": "LUBUKLINGGAU" },
  { "label": "PAGAR ALAM", "value": "PAGAR ALAM" },
  { "label": "PALEMBANG", "value": "PALEMBANG" },
  { "label": "PRABUMULIH", "value": "PRABUMULIH" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

const opds = [
  { "label": "DINAS PENDIDIKAN", "value": "DINAS PENDIDIKAN" },
  { "label": "DINAS KESEHATAN", "value": "DINAS KESEHATAN" },
  { "label": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG", "value": "DINAS PEKERJAAN UMUM DAN PENATAAN RUANG" },
  { "label": "DINAS PERHUBUNGAN", "value": "DINAS PERHUBUNGAN" },
  { "label": "DINAS SOSIAL", "value": "DINAS SOSIAL" },
  { "label": "DINAS PERTANIAN", "value": "DINAS PERTANIAN" },
  { "label": "DINAS KETAHANAN PANGAN", "value": "DINAS KETAHANAN PANGAN" },
  { "label": "DINAS LINGKUNGAN HIDUP", "value": "DINAS LINGKUNGAN HIDUP" },
  { "label": "DINAS PARIWISATA", "value": "DINAS PARIWISATA" },
  { "label": "DINAS PERDAGANGAN", "value": "DINAS PERDAGANGAN" },
  { "label": "DINAS PERINDUSTRIAN", "value": "DINAS PERINDUSTRIAN" },
  { "label": "DINAS KOMUNIKASI DAN INFORMATIKA", "value": "DINAS KOMUNIKASI DAN INFORMATIKA" },
  { "label": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL", "value": "DINAS KEPENDUDUKAN DAN PENCATATAN SIPIL" },
  { "label": "DINAS PEMUDA DAN OLAHRAGA", "value": "DINAS PEMUDA DAN OLAHRAGA" },
  { "label": "DINAS PERPUSTAKAAN DAN KEARSIPAN", "value": "DINAS PERPUSTAKAAN DAN KEARSIPAN" },
  { "label": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK", "value": "DINAS PEMBERDAYAAN PEREMPUAN DAN PERLINDUNGAN ANAK" },
  { "label": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA", "value": "DINAS PEMBERDAYAAN MASYARAKAT DAN DESA" },
  { "label": "DINAS KOPERASI DAN UMKM", "value": "DINAS KOPERASI DAN UMKM" },
  { "label": "BADAN PERENCANAAN PEMBANGUNAN DAERAH", "value": "BADAN PERENCANAAN PEMBANGUNAN DAERAH" },
  { "label": "BADAN PENGELOLAAN KEUANGAN DAERAH", "value": "BADAN PENGELOLAAN KEUANGAN DAERAH" },
  { "label": "BADAN KEPEGAWAIAN DAERAH", "value": "BADAN KEPEGAWAIAN DAERAH" },
  { "label": "BADAN PENGAWASAN PEMBANGUNAN DAERAH", "value": "BADAN PENGAWASAN PEMBANGUNAN DAERAH" },
  { "label": "BADAN PENDAPATAN DAERAH", "value": "BADAN PENDAPATAN DAERAH" },
  { "label": "SEKRETARIAT DAERAH", "value": "SEKRETARIAT DAERAH" },
  { "label": "SEKRETARIAT DPRD", "value": "SEKRETARIAT DPRD" },
  { "label": "INSPEKTORAT DAERAH", "value": "INSPEKTORAT DAERAH" },
  { "label": "LAINNYA", "value": "LAINNYA" },
]

export const USER = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation()

  const [companies, setCompanies] = useState([])
  const [role, setRole] = useState("user")

  const handleChange = (value) => {
    setRole(value)
  };

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData(values)
    } else {
      createData(values)
    }
  }

  const createData = async (values) => {
    try {
      await dispatch(addUser(values)).unwrap()
      history.push("/app/users")
    } catch (error) {
      message.error(error?.message || 'Failed to register')
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateUser({ ...values, id: location?.state?.id })).unwrap()
      history.push("/app/users")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneUser(id)).unwrap()
      form.setFieldsValue({
        ...data.data
      });
    } catch (err) {
      console.log(err)
    }
  }

  const getAllCompanies = async () => {
    try {
      const { data } = await dispatch(fetchAllWajib({ limit: 1000 })).unwrap();
      const list_pt = data.data.map(item => ({
        value: item.perusahaan,
        label: item.perusahaan
      }));

      setCompanies(list_pt);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllCompanies()
    if (location?.state?.id) {
      getDataById(location?.state?.id)
    }
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tambah/Update Data Pengguna</h2>
          <p>Tolong isikan data sesuai dengan data pengguna</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Please input your email!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Perusahaan"
                name="perusahaan"
                rules={[
                  {
                    required: true,
                    message: 'Please input your company!',
                  },
                ]}
              >
                <Select options={companies} />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input password!',
                  },
                ]}
              >
                <Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default USER