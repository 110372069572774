import { Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import StatisticWidget from "components/shared-components/StatisticWidget";
import { useDispatch } from "react-redux";
import { fetchProjectSummary } from "redux/features/projects";
import { fetchAllProjects, deleteProject } from "redux/features/projects";
import { fetchWajibSummary } from "redux/features/wajib_pajak";
import { getUserProfile } from "redux/features/auth";
import { PROJECTS } from "../projects";

let IDRFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'IDR',
});

export const DefaultDashboard = () => {
  const dispatch = useDispatch();
  const [target, setTarget] = useState(0);
  const [totalProjects, setTotalProjects] = useState(0);
  const [myProjects, setMyProjects] = useState(0);
  const [myTarget, setMyTarget] = useState(0);
  const [myActual, setMyActual] = useState(0);
  const [perusahaan, setPerusahaan] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [actual, setActual] = useState(0);
  const [role, setRole] = useState(0);
  const [company, setCompany] = useState("");

  const getAllDashboardData = async (params) => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      const summary = await dispatch(fetchProjectSummary()).unwrap();
      const mySummary = await dispatch(fetchProjectSummary({owner:response.data.user.perusahaan})).unwrap();
      setMyProjects(mySummary.data.totalProjects)
      setMyActual(mySummary.data.totalAmount._sum.actual || 0);
      setMyTarget(mySummary.data.totalAmount._sum.cost || 0);

      setActual(summary.data.totalAmount._sum.actual || 0);
      setTarget(summary.data.totalAmount._sum.cost || 0);
      setTotalProjects(summary.data.totalProjects)
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const getData = async (params) => {
    try {
      const response = await dispatch(fetchAllProjects({
        ...params,
        perusahaan: params.perusahaan
      })).unwrap();
      setLoading(false);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  }

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      setRole(response.data.user.role_id)
      setCompany(response.data.user.perusahaan)
      getData({
        perusahaan: response.data.user.perusahaan
      });
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  useEffect(() => {
    getProfile()
    getAllDashboardData()
  }, []);

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Dashboard</h2>
          <p>
            Jika menemukan bug ataupun error pada aplikasi ini bisa langsung hubungi kami di 085899731884
          </p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={1}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Semua Proyek CSR`}
                value={totalProjects}
              />
            </Col>
             {role === 2 && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Total Proyek CSR Saya`}
                  value={myProjects}
                />
              </Col>
              
            )}
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={3}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Dana CSR Dibutuhkan`}
                value={IDRFormat.format(role === 2 ? myTarget : target)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={4}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Total Dana CSR Tersalurkan`}
                value={IDRFormat.format(role === 2 ? myActual : actual)}
              />
            </Col>
            {role === 2 && (
              <Col xs={24} sm={24} md={24} lg={12} xl={12} key={2}>
                <StatisticWidget
                  style={{ textAlign: "center" }}
                  title={`Progress CSR Saya Tersalurkan`}
                  value={<div style={{ color: (myActual / myTarget * 100).toFixed(2) < 50 ? 'red' : 'green' }}>{`${(myActual / myTarget * 100).toFixed(2)}%`}</div>}
                />
              </Col>
            )}
            <Col xs={24} sm={24} md={24} lg={12} xl={12} key={5}>
              <StatisticWidget
                style={{ textAlign: "center" }}
                title={`Progress Semua CSR Tersalurkan`}
                value={<div style={{ color: (actual / target * 100).toFixed(2) < 50 ? 'red' : 'green' }}>{`${(actual / target * 100).toFixed(2)}%`}</div>}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <PROJECTS></PROJECTS>
            </Col>
          </Row>
        </Col>
      </Row>

    </>
  );
};

export default withRouter(DefaultDashboard);
