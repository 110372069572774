import { Button, Card, Col, message, Row, Table, Input, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { strings } from "res";
import { fetchAllProjects, deleteProject } from "redux/features/projects";
import { getUserProfile } from "redux/features/auth";
import moment from "moment";

// Format the price above to USD using the locale, style, and currency.
let IDRFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'IDR',
});

const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="确认"
        cancelText="取消"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const PROJECTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [role, setRole] = useState(0);
  const [metaData, setMetaData] = useState({});
  const [modal, contextHolder] = Modal.useModal();

  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    // sort: "created_at",
  });

  const { Search } = Input;

  const getProfile = async () => {
    try {
      const response = await dispatch(getUserProfile()).unwrap();
      setRole(response.data.user.role_id)
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Kategori",
      dataIndex: "kategori",
      key: "kategori",
    },
    // {
    //   title: "Alamat Lengkap",
    //   dataIndex: "lokasi",
    //   key: "lokasi",
    // },
    {
      title: "Kabupaten",
      dataIndex: "kabupaten",
      key: "kabupaten",
    },
    {
      title: "OPD",
      dataIndex: "opd",
      key: "opd",
    },
    // {
    //   title: "Kecamatan",
    //   dataIndex: "kecamatan",
    //   key: "kecamatan",
    // },
    // {
    //   title: "Kelurahan",
    //   dataIndex: "kelurahan",
    //   key: "kelurahan",
    // },
    {
      title: "Nominal",
      dataIndex: "cost",
      key: "cost",
      render: (_, record) => (
        <div>
          {IDRFormat.format(record.cost)}
        </div>
      ),
    },
    {
      title: "Pemilik Proyek",
      dataIndex: "owner",
      key: "owner",
    },
    {
      title: "Pelaksana",
      dataIndex: "pelaksana",
      key: "pelaksana",
      render: (_, record) => (
        <div>
          {record.pelaksana ? <div style={{ color: "green", font: "bold" }}>{record.pelaksana}</div> : <div style={{ color: "red", font: "bold" }}>Belum Ditentukan</div>}
        </div>
      ),
    }, {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        if (record.status === "OPEN") {
          return <div className="text-green text-center">
            <b style={{ color: "green", font: "bold" }}>OPEN</b>
          </div>
        } else {
          if (record.status === "CLOSED") {
            return <div className="text-green text-center">
              <b style={{ color: "red", font: "bold" }}>CLOSED</b>
            </div>
          }
        }
      },
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (_, record) => (
        <div>
          {moment(record.startDate).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "status",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_project}`,
                state: record,
              });
            }}
          >
            Detail
          </Button>
        </div>
      ),
    },
    {
      title: () => <div className="text-center">Action</div>,
      key: "status",
      render: (_, record) => {
        if (role === 1) {
          return (
            <div className="text-center">
              <Button
                type="danger"
                style={{ textAlign: "center", color: "white" }}
                onClick={() => {
                  confirm(record?.id);
                }}
              >
                Delete
              </Button>
            </div>
          )
        } else {
          return (
            <div className="text-center">
              <Button
                type="primary"
                style={{ textAlign: "center", color: "white" }}
                onClick={() => {
                  // confirm(record?.id);
                }}
              >
                Ambil Proyek
              </Button>
            </div>
          )
        }
      }
    },
  ];

  const getData = async (params) => {
    try {
      const response = await dispatch(fetchAllProjects(params)).unwrap();
      setData(response.data.Projects);
      setMetaData(response.meta);
      setLoading(false);
    } catch (error) {
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    //sort by date_published
    let sorterKey = "";
    // if (sorter.field === "date_published" && sorter.order) {
    //   sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
    //   setFilters({ ...filters, page: 1, sort: sorterKey });
    // }

    // if (sorter.field === "created_at" && sorter.order) {
    //   sorterKey = sorter.order === "ascend" ? sorter.field : `-${sorter.field}`;
    //   setFilters({ ...filters, page: 1, sort: sorterKey });
    // }

    setFilters({ ...filters, page: pagination.current, sort: sorterKey });
  };

  const handleSearchByTitle = (event) => {
    setFilters({ ...filters, name:event.target.value });
  }

  const handleSearchByOPD = (event) => {
    console.log(event.target)
    setFilters({ ...filters, opd:event.target.value });
  }

  const handleSearchPerusahaan = (event) => {
    console.log(event.target)
    setFilters({ ...filters, owner:event.target.value });
  }

  const handleSearchKabupaten = (event) => {
    console.log(event.target)
    setFilters({ ...filters, kabupaten:event.target.value });
  }

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteProject(id));
        getData();
      },
      onCancel: () => { },
    });
  };

  useEffect(() => {
    getData(filters);
    getProfile()
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Proyek CSR Berjalan di Sumatera Selatan</h2>
          <p>Daftar semua proyek CSR berjalan di Sumatera Selatan</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Row gutter={[6, 6]}>
              <Col md={8} xl={8} sm={24} >
                <Input onChange={handleSearchByTitle} name="name" placeholder="Cari Berdasarkan Judul Proyek"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Input onChange={handleSearchKabupaten} name="kabupaten" placeholder="Kota/Kabupaten"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Input onChange={handleSearchByOPD} name="opd" placeholder="OPD"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Input onChange={handleSearchPerusahaan} name="owner" placeholder="Perusahaan"></Input>
              </Col>
              <Col md={4} xl={4} sm={24} >
                <Button type="primary" style={{ width: "100%" }}>Cari</Button>
              </Col>
            </Row>
            <Table
              className="no-border-last"
              columns={tableColumns}
              scroll={{ x: 'max-content' }} // enables horizontal scrolling
              dataSource={data}
              rowKey="id"
              pagination={{
                defaultPageSize: 10,
                defaultCurrent: 1,
                total: metaData.total_data,
              }}
              onChange={handleTableChange}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_project}`,
              });
            }}
            block
          >
            Tambahkan Proyek TJSL
          </Button>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default withRouter(PROJECTS);
